import React from "react"
import {bindable, bindingMode, customElement, inject, noView} from "aurelia-framework"
import {render, unmountComponentAtNode} from "react-dom"
import {Client} from "../../api/client"
import {CurrencyValueConverter} from "../../currency/currency-value-converter"
import EntriesTable from "./entries-table"
import {UserClient} from "../../api/user-client"
import AccountingProvider from "../accounting-provider"
import Toolbar from "./toolbar"
import EntryEditor from "../entry/editor";

@noView
@inject(Element, CurrencyValueConverter, Client, UserClient)
@customElement('accounting-ledger-entries')
export class LedgerEntries {
    @bindable({defaultBindingMode: bindingMode.oneTime}) account;
    @bindable({defaultBindingMode: bindingMode.oneTime}) organization;
    @bindable({defaultBindingMode: bindingMode.oneTime}) costObject;
    @bindable({defaultBindingMode: bindingMode.oneTime}) reference;
    @bindable({defaultBindingMode: bindingMode.oneTime}) stack;
    @bindable({defaultBindingMode: bindingMode.oneTime}) context;

    constructor(container, currency, client, userClient) {
        this.container = container;
        this.currency = currency;
        this.userClient = userClient;
        this.currentIndex = 0;
    }

    async bind() {
        this.organization = this.organization?.id ?? this.userClient.getUser().organization.id
        this.context = this.context ?? (
            this.account ? "account" :
                this.costObject ? "costObject" :
                    this.reference ? "reference" :
                        this.stack ? "stack" :
                            "journal"
        );
    }

    /**
     * @fixme we don't need this anymore when whole layout is grid based
     */
    _resize() {
        this.container.style.display = "block"
        this.container.style.height = (document.documentElement.clientHeight - this.container.getBoundingClientRect().top - 80) + "px";
    }

    attached() {
        document.addEventListener('keydown', this.handleTabKey);
        document.addEventListener('resize', this._resize);
        this._resize();
        return this.render();
    }
    //enable form to move the input fields when press tab
    handleTabKey = (event) => {
        if (event.key === 'Tab') {
           event.preventDefault(); // Prevent the default tab behavior
           const inputElements = Array.from(document.querySelectorAll('input'));
           const getElementById = document.getElementById('submit-booking')
           let filteredArray = inputElements.filter(obj => obj.id != "" || obj.name )?.slice(2);
           filteredArray = [...filteredArray ,getElementById ]
           let firstElement = filteredArray.shift();
           filteredArray.push(firstElement);
           if( filteredArray && filteredArray.length > 0 && this.currentIndex != NaN){
            let arr = filteredArray[this.currentIndex].classList;
                if (event.shiftKey) {
                    // Shift + Tab: Move backward
                    if(arr[0] === 'rs-picker-toggle-textbox'){
                        if(filteredArray[this.currentIndex].id == 'bookDate' || filteredArray[this.currentIndex].id == 'receiptDate' ){
                            filteredArray[this.currentIndex].focus();
                        }else{
                            filteredArray[this.currentIndex].click();
                        }
                    }else{
                        filteredArray[this.currentIndex].focus();
                    }
                    this.currentIndex = (this.currentIndex - 1 + filteredArray.length) % filteredArray.length;
                } else {
                    if(arr[0] === 'rs-picker-toggle-textbox'){
                        if(filteredArray[this.currentIndex].id == 'bookDate' || filteredArray[this.currentIndex].id == 'receiptDate' ){
                            filteredArray[this.currentIndex].focus();
                        }else{
                            filteredArray[this.currentIndex].click();
                        }
                    }else{
                        filteredArray[this.currentIndex].focus();
                    }
                    // Tab: Move forward
                    this.currentIndex = (this.currentIndex + 1) % filteredArray.length;
                }
           }
        }
    };

    async render() {
        if (undefined === this.additional) {
            this.additional = {
                canDialogBooking: !!await this.userClient.hasRole("accounting.dialog-booking")
            }
            for (const property of ["organization", "stack", "account", "costObject", "reference"]) {
                if (this[property]) {
                    this.additional[property] = this[property]
                }
            }
        }

        render(
            <AccountingProvider context={this.context} additional={this.additional}>
                <Toolbar/>
                <EntriesTable/>
                <EntryEditor/>
            </AccountingProvider>,
            this.container
        );
    }

    detached() {
        document.removeEventListener('resize', this._resize);
        document.removeEventListener('keydown', this.handleTabKey);
        unmountComponentAtNode(this.container);
    }
}

export default LedgerEntries;
