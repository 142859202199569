import React from "react"
import {Dropdown} from "rsuite"
import {useDispatch, useSelector} from "react-redux"
import {selectOrganization} from "../../store/entries-slice"
import {selectCurrentPeriod, setCurrentPeriod, usePeriodsByOrganization} from "../../store/state-slice"

export default function PeriodSelect() {
    const dispatch = useDispatch()
    const organization = useSelector(selectOrganization)
    const period = useSelector(selectCurrentPeriod(organization))
    const periods = usePeriodsByOrganization(organization)

    function switchPeriod(id) {
        dispatch(setCurrentPeriod(organization, id))
    }

    return (
        <Dropdown size="sm" title={periods.find(({id}) => period === id)?.label ?? "Geschäftsjahr"}>
            <Dropdown.Item onClick={() => switchPeriod(null)}>(alle Geschäftsjahre)</Dropdown.Item>
            {periods.map(({id, label}) => (
                <Dropdown.Item key={id} onClick={() => switchPeriod(id)}>{label}</Dropdown.Item>
            ))}
        </Dropdown>
    )
}
