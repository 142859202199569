import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {Dropdown} from "rsuite"
import {selectOrganization, setOrganization} from "../../store/entries-slice"
import {selectOrganizations} from "../../store/state-slice"

export default function OrganizationSelect() {
    const dispatch = useDispatch()
    const organization = useSelector(selectOrganization)
    const organizations = useSelector(selectOrganizations)

    return (
        <Dropdown size="sm" title={(organizations.find(({id}) => id === organization)?.label) ?? 'Organisation'}>
            {organizations.map(({id, label}) => (
                <Dropdown.Item key={id} onClick={() => dispatch(setOrganization(id))}>{label}</Dropdown.Item>
            ))}
        </Dropdown>
    )
}
