import React from "react"
import { Tooltip, Whisper} from "rsuite"
import {useSelector} from "react-redux"
import {selectAccountingStates} from "../store/state-slice"
import SioIcon from "../../icon/rsuite-icon-font/SioIcon"

function StateIcon({state, cleared}) {
    const states = useSelector(selectAccountingStates)
    const label = cleared ? "ausgeziffert" : states[state].label
    const icon = cleared ? " fa fa-box" : states[state].icon

    return (
        <Whisper trigger="hover" delayShow={500} placement="bottomStart" speaker={<Tooltip>{label}</Tooltip>}>
            <SioIcon icon={icon}/>
        </Whisper>
    )
}

export default StateIcon
