import {useContext} from "react"
import {useDispatch, useSelector} from "react-redux"
import AureliaContext from "../../../utilities/aurelia-context";
import {Button, ButtonGroup, Tooltip, Whisper} from "rsuite"
import SioIcon from "../../../icon/rsuite-icon-font/SioIcon"
import {
    selectHasCleared,
    selectHasUncleared,
    selectSelectedStates,
    selectShowCleared,
    selectShowUncleared,
    selectUsedStates,
    toggleSelectedState,
    toggleShowCleared,
    toggleShowUncleared
} from "../../store/entries-slice"

export default function StatesSelect() {
    const dispatch = useDispatch()
    const {i18n} = useContext(AureliaContext)
    const list = useSelector(selectUsedStates)
    const states = useSelector(selectSelectedStates)
    const hasCleared = useSelector(selectHasCleared)
    const hasUncleared = useSelector(selectHasUncleared)
    const showCleared = useSelector(selectShowCleared)
    const showUncleared = useSelector(selectShowUncleared)

    return 2 > list.length + (hasCleared ? 1 : 0) ? null : (
        <ButtonGroup size="sm">
            {1 < list.length && list.map(({id, icon, label}) => (
                <Whisper key={id} trigger="hover" delayShow={500} placement="top" speaker={<Tooltip>{label}</Tooltip>}>
                    <Button active={states.includes(id)}
                            onClick={() => dispatch(toggleSelectedState(id))}>
                        <SioIcon icon={icon}/>
                    </Button>
                </Whisper>
            ))}

            {hasUncleared && (
                <Whisper trigger="hover" delayShow={500} placement="top"
                         speaker={<Tooltip>{i18n.tr("accounting.booking.cleared")}</Tooltip>}>
                    <Button active={showUncleared}
                            onClick={() => dispatch(toggleShowUncleared())}>
                        <SioIcon icon="fa fa-lock"/>
                    </Button>
                </Whisper>
            )}

            {hasCleared && (
                <Whisper trigger="hover" delayShow={500} placement="top"
                         speaker={<Tooltip>{i18n.tr("accounting.booking.cleared")}</Tooltip>}>
                    <Button  active={showCleared}
                            onClick={() => dispatch(toggleShowCleared())}>
                        <SioIcon icon=" fa fa-box"/>
                    </Button>
                </Whisper>
            )}
        </ButtonGroup>
    )
}
