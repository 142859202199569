import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {Input, InputGroup} from "rsuite"
import {selectSearch, setSearch} from "../../store/entries-slice"
import {useDebouncedChange} from "../../../utilities/debounce"
import SioIcon from "../../../icon/rsuite-icon-font/SioIcon"

export default function Searchbox() {
    const dispatch = useDispatch()
    const search = useSelector(selectSearch)
    const [value, setValue] = useDebouncedChange(search, 250, value => dispatch(setSearch(value)))

    return (
        <InputGroup inside size="sm">
            <Input value={value} onChange={v => setValue(v)}/>
            <InputGroup.Button>
                <SioIcon icon="fa fa-search"/>
            </InputGroup.Button>
        </InputGroup>
    )
}
