import React, {useContext} from "react"
import {useSelector} from "react-redux"
import {IconButton} from "rsuite"
import {confirm} from "../../../dialog/confirm"
import {selectAccount, selectSelected} from "../../store/entries-slice"
import {useClearBookingsMutation} from "../../store/accounting-api"
import SioIcon from "../../../icon/rsuite-icon-font/SioIcon"
import AureliaContext from "../../../utilities/aurelia-context"

export default function ClearButton() {
    const {i18n} = useContext(AureliaContext)
    const selected = useSelector(selectSelected)
    const account = useSelector(selectAccount)
    const [doAction, {isLoading}] = useClearBookingsMutation()
    const label = i18n.tr("accounting.booking.clear")

    function onClick() {
        confirm(label, i18n.tr("sio.are_you_sure")).then(() => doAction({bookings: selected, account}))
    }

    return (
        <IconButton
            size="xs" color="yellow"
            loading={isLoading}
            onClick={() => onClick()}
            icon={<SioIcon icon=" fa fa-box"/>}>

            {label}
        </IconButton>
    )
}
