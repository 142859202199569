import {useDispatch, useSelector} from "react-redux";
import {newBooking, selectEditorDrawer} from "../../store/editor-slice";
import {selectOrganization, selectStack} from "../../store/entries-slice";
import {selectCurrentPeriod} from "../../store/state-slice";
import {loadAccountingBooking} from "../../store/accounting-api";
import {Button, IconButton} from "rsuite";
import SioIcon from "../../../icon/rsuite-icon-font/SioIcon";
import React from "react";

export default function EditButton({id, small = false, icon = "edit2", label = "Bearbeiten", ...props}) {
    const dispatch = useDispatch()
    const [open] = useSelector(selectEditorDrawer)
    const organization = useSelector(selectOrganization)
    const stack = useSelector(selectStack)
    const period = useSelector(selectCurrentPeriod(organization))

    function handleClick() {
        dispatch(id ?
            loadAccountingBooking(id, {forceRefetch: true}) :
            newBooking({organization, period, stack})
        )
    }

    return small ? (
        <Button size="xs" disabled={open} onClick={() => handleClick()} {...props}>
            <SioIcon icon={icon}/>
        </Button>
    ) : (
        <IconButton
            size="sm" color="red" disabled={open}
            style={{
                height: "25px",
                alignItems: "center"
            }}
            onClick={() => handleClick()}
            icon={<SioIcon icon={icon}/>}
            {...props}>

            {label}
        </IconButton>
    )
}
